/*
Circle-u font-roboto-flex.less

Variable font. Same file for all. No dedicated italic.
*/



@font-face {
  font-family: "Roboto Flex";
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  font-display: swap;
  //src: url(https://fonts.gstatic.com/s/robotoflex/v9/NaNeepOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGfttPZktqc2VdZ80KvCLZaPcSBZtOx2MifRuWR28sPJtUMbsFEK6cRrleUx9Xgbm3WLHa_F4Ep4Fm0PN19Ik5Dntczx0wZGzhPlL1YNMYKbv9_1IQXOw7AiUJVXRrV8cWW4O8LJCoXjCnwSRSaLshNP1d9-EmF8tqHbE.woff2) format('woff2');
  src: url("../fonts/roboto-flex.woff2") format("woff2");
}
@font-face {
  font-family: "Roboto Flex";
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url("../fonts/roboto-flex.woff2") format("woff2");
}
@font-face {
  font-family: "Roboto Flex";
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url("../fonts/roboto-flex.woff2") format("woff2");
}
@font-face {
  font-family: "Roboto Flex";
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url("../fonts/roboto-flex.woff2") format("woff2");
}
@font-face {
  font-family: "Roboto Flex";
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url("../fonts/roboto-flex.woff2") format("woff2");
}
