/* circle u course catalogue (listing and single course page) */



// Shared rules for both listing and single course page.
// (Course-catalogue class is used on both pages).

#vrtx-circleU-listing,
#vrtx-circleU-course {

  .course-attributes {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      padding-right: 10px;
      margin: 0 10px 10px 0;
      line-height: 1.2;
      &:not(:last-child) {
        border-right: 1px solid var(--color-neutral-tint-80);
      }
    }
  }
}



// List page

#vrtx-circleU-listing {

  .course-catalogue {
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }
    input,
    select,
    button {
      background-color: white;
      border: 1px solid var(--color-primary-1-tint-70);
      border-bottom: 1px solid var(--color-primary-1);
      transition: all .3s;
      &:hover {
        box-shadow: 0 2px 6px rgba(0,0,0, .3);
      }
    }
    label {
      display: block;
      margin-bottom: 5px;
    }

    // filters-form (contains freetext-search, dropdowns and submit)

    .filters-form {
      background-color: var(--color-primary-2-tint-95);
      margin-bottom: 30px;
      padding: 20px;
      @media (@screen-medium) {
        padding: 30px;
      }
    }

    // freetext-search

    .freetext-search {
      display: grid;
      grid-template-columns: 1fr 40px;
      column-gap: 10px;
      max-width: 450px;
      margin-bottom: 20px;
      label {
        grid-column-start: 1;
        grid-column-end: 3;
      }
      input {
        grid-column-start: 1;
        width: 100%;
      }
      button {
        grid-column-start: 2;
        background: url("../images/search.svg") no-repeat 6px 8px;
        background-color: white;
        background-size: 24px auto;
        // Button text hidden unless screen-reader
        span {
          .invisible;
        }
      }
    }

    // filters (dropdowns)

    .filters {
      margin-bottom: 30px;
      ul {
        display: flex;
        gap: 20px;
        @media (max-width: 700px) {
          flex-wrap: wrap;
        }
      }
      select {
        width: 100%; // works like max-width
        min-width: 60px;
      }
    }

    // submit-button

    .submit-filters {
      background-color: var(--color-primary-1);
      border-color: transparent;
      color: white;
      padding: 5px 15px;
    }

    // active-filters

    .active-filters {
      margin: -15px 0 30px;
      h3 {
        display: inline-block;
        font-size: inherit;
        margin: 0 5px 0 0;
        &::after {
          content: ":";
        }
      }
      ul {
        display: inline-flex;
        flex-wrap: wrap;
      }
      li {
        margin: 0 10px 10px 0;
        a {
          display: flex;
          border: 1px solid var(--color-primary-1-tint-70);
          border-radius: var(--radius);
          padding-left: 10px;
          color: inherit;
          text-decoration: none;
          &::after {
            content: "";
            flex-shrink: 0;
            background: url("../images/x-small.svg") no-repeat center;
            background-size: 12px;
            width: 30px;
            margin-left: 5px;
            border-radius: 0 10px 10px 0;
            transition: all .3s;
          }
          &:focus,
          &:hover {
            &::after {
              background-color: var(--color-primary-2);
            }
          }
        }
      }
      // temp "clear filters" button. HTML error, so class doesn't work.
      .clear-filters,
      > a {
        display: inline-block;
      }
    }

    // results

    .results {
      margin-bottom: 80px;
      h3 {
        color: var(--color-primary-1);
        margin-bottom: 10px;
      }
      a {
        text-decoration: none;
      }
      > ul > li {
        margin-bottom: 20px;
        a {
          display: block;
          border-radius: var(--radius);
          color: inherit;
          box-shadow: 0 1px 3px rgba(0,0,0, .2);
          transition: all .3s;
          padding: 15px 20px 10px;
          @media (@screen-medium) {
            padding: 30px 30px 25px;
          }
          &:focus,
          &:hover {
            text-decoration: none;
            background-color: var(--color-primary-2-tint-90);
            box-shadow: 0 2px 6px rgba(0,0,0, .3);
          }
        }
      }
    }

    // pager

    .vrtx-paging-wrapper {
      margin-top: 40px;
    }
  }
}



// Single course page

#vrtx-circleU-course {

  #vrtx-content {
    @media (@screen-large) {
      margin-left: 200px; // Same indent as articles
    }
  }

  #vrtx-breadcrumb {
    // Hide breadcrumb level above, which is not in use.
    span:nth-last-child(2) {
      display: none;
    }
  }

  .course-catalogue {
    .vrtx-back {
      margin-bottom: 25px;
      a {
        text-decoration: none;
        &::before {
          content: "";
          display: inline-block;
          width: 1em;
          height: .8em;
          margin-right: 10px;
          background: url("../images/arrow.svg") no-repeat left center;
          background-size: contain;
          transform: rotate(180deg);
        }
        &:hover {
          text-decoration: underline;
        }
      }
    }

    h1 {
      margin-bottom: 10px;
    }

    // course-attributes
    .course-attributes {
      margin: 0 0 25px 0;
    }

    // course-description
    #vrtx-course-description {
      margin-bottom: 40px;
      max-width: 700px;
    }
  }
}
