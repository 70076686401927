/*
Circle-u font-roboto-serif.less

Extra condensed variant, determined by font-stretch property.
Variable font. One file for italic, one for normal/non-italic.
*/



// Italic

@font-face {
  font-family: 'Roboto Serif';
  font-style: italic;
  font-weight: 400;
  font-stretch: 62.5%;
  font-display: swap;
  //src: url(https://fonts.gstatic.com/s/robotoserif/v13/R705jywflP6FLr3gZx7K8UyEVQnyR1E7VN-f51xYuGCQepO9CRLLcmv0wLiC-wIkpF7hGvo.woff2) format('woff2');
  src: url('../fonts/roboto-serif-italic.woff2') format('woff2');
}
@font-face {
  font-family: 'Roboto Serif';
  font-style: italic;
  font-weight: 500;
  font-stretch: 62.5%;
  font-display: swap;
  src: url('../fonts/roboto-serif-italic.woff2') format('woff2');
}
@font-face {
  font-family: 'Roboto Serif';
  font-style: italic;
  font-weight: 600;
  font-stretch: 62.5%;
  font-display: swap;
  src: url('../fonts/roboto-serif-italic.woff2') format('woff2');
}



// Normal/non-italic

@font-face {
  font-family: 'Roboto Serif';
  font-style: normal;
  font-weight: 400;
  font-stretch: 62.5%;
  font-display: swap;
  //src: url(https://fonts.gstatic.com/s/robotoserif/v13/R707jywflP6FLr3gZx7K8UyuXDs9zVwDmXCb8lxYgmuimXPEWoRldX6OgtLHywAuo3zj.woff2) format('woff2');
  src: url('../fonts/roboto-serif.woff2') format('woff2');
}
@font-face {
  font-family: 'Roboto Serif';
  font-style: normal;
  font-weight: 500;
  font-stretch: 62.5%;
  font-display: swap;
  src: url('../fonts/roboto-serif.woff2') format('woff2');
}
@font-face {
  font-family: 'Roboto Serif';
  font-style: normal;
  font-weight: 600;
  font-stretch: 62.5%;
  font-display: swap;
  src: url('../fonts/roboto-serif.woff2') format('woff2');
}