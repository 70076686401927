// www.circle-u.eu x1 local.less


// X-styles
@import "../../../../../../../lib/x1/css/x-0.less";

// Local imports
@import "font-roboto-flex.less";
@import "font-roboto-serif.less";
@import "course-catalogue.less";



// Vars

:root {
  --color-primary-1: #00649c;
  --color-primary-1-tint-70: #00649c60; // Hex alpha
  --color-primary-2: #83d0f5;
  --color-primary-2-tint-90: #83d0f550;
  --color-primary-2-tint-95: #83d0f530;
  --color-primary-3: #be1d28;
  --color-secondary-2: #F4F2F7;

  // X-overrides
  --color-neutral-tint-80: var(--color-primary-2);
  --color-neutral-tint-90: var(--color-primary-2-tint-90);
  --color-neutral-tint-95: var(--color-primary-2-tint-95);
  --radius: 10px;
}



// General

body {
  font-family: "Roboto Flex", sans-serif;
}

h1 {
  @media (@screen-medium) {
    font-size: 6rem;
    margin-bottom: 30px;
  }
}



// Head admin menu

#head-admin-menu-wrapper {
  display: none;
}



// Header

#head-wrapper {
  border-bottom: 1px solid var(--color-primary-2);
  box-shadow: 0 0 0 3px var(--color-primary-2-tint-90);
  padding: 30px 0;
  @media (@screen-below-medium) {
    padding: 15px 0;
  }
}

#header-logo {
  display: flex;
  margin-right: auto;
  background: url("../images/logo.svg") no-repeat left center;
  background-size: contain;
  width: 300px;
  height: 80px;
  @media (@screen-below-medium) {
    width: 200px; // Will shrink if needed
    height: 50px;
  }
  a {
    // Fill area (flex parent)
    width: 100%;
    height: 100%;
  }
  span {
    .invisible;
  }
}

#header-tools {
  grid-template-columns: auto auto; // One less auto, since language removed
  @media (@screen-medium) {
    column-gap: 40px;
  }
  #header-language {
    display: none;
  }
  .toggle-search,
  .sidebar-menu-toggle {
    color: var(--color-primary-1);
    &::before {
      background-size: auto;
      width: 40px;
      height: 40px;
      @media (@screen-medium) {
        margin-right: 15px;
      }
    }
  }
}



// Breadcrumbs

#breadcrumbs {
  margin-top: 15px;
  @media (@screen-medium) {
    margin-bottom: 40px;
  }
  // Include second to last crumb on mobile
  span:nth-last-child(3) {
    @media (@screen-below-medium) {
      display: inline-flex;
    }
  }
}



// Main menu

.sidebar-menu-wrapper {
  .sidebar-menu {
    padding-bottom: 20px;
    border-bottom: 1px solid var(--color-primary-2);
  }
  .sidebar-global-menu {
    border: none;
  }
  .vrtx-tab-menu {
    a {
      color: var(--color-primary-1);
      border-left: 5px solid transparent;
      font-weight: 300;
      font-size: 2.4rem;
      @media (@screen-medium) {
        font-size: 3.6rem;
      }
      &:hover {
        border-color: var(--color-primary-3);
      }
    }
  }
}



// not in use

#global-sub-menu,
#vrtx-collections {
  display: none;
}



// Footer

#footer-wrapper {
  background-color: var(--color-primary-1);
  padding-bottom: 100px;

  button {
    color: inherit;
  }

  // EU emblem
  .eu-emblem {
    display: flex;
    align-items: center;
    gap: 15px;
    span {
      max-width: 250px;
    }
  }

  #privacy-notification {
    background-color: white;
    box-shadow: 0 0 5px rgba(0,0,0, .3);
    color: black;
    button {
      border-color: var(--color-neutral-tint-30);
    }
  }
}



// The frontpage

.the-frontpage {
  // When media-wide is first item (after introduction-wrapper)
  &:has(#vrtx-introduction-wrapper + .media-wide) {
    #head-wrapper {
      border: none;
      box-shadow: none;
    }
    #breadcrumbs {
      display: none;
    }
    .sidebar-menu-wrapper {
      border-top: 1px solid var(--color-primary-2);
    }
  }
}



// Introduction and blockquote (use serif-font)

.vrtx-introduction,
blockquote p:first-child {
  font-family: "Roboto Serif", serif;
  color: var(--color-primary-1);
}

#vrtx-structured-article {
  .vrtx-introduction {
    @media (@screen-medium) {
      margin-bottom: 70px;
    }
  }
}

#vrtx-structured-event {
  .vrtx-introduction {
    margin-bottom: 30px;
  }
}

#vrtx-frontpage {
  .grid-container {
    // Allowing not-so-ideal usage within row.
    .vrtx-introduction {
      padding: 0;
    }
  }
}



// Links, buttons etc.

a {
  text-decoration-thickness: 1px;
  text-underline-offset: .05em;
}

#main a {
  color: var(--color-primary-1);
}

.read-more,
.vrtx-more a {
  font-weight: 600;
}

input {
  border-color: var(--color-primary-1);
}

button {
  color: var(--color-primary-1);
}

#main { // main added to override general links rule
  .button,
  .button-large,
  .navigation-links li a {
    padding-left: 20px;
    border-color: transparent;
    background-color: var(--color-primary-1);
    color: white;
    &::after {
      background-image: url("../images/arrow-white.svg");//
    }
  }
  .button-large {
    background-color: var(--color-primary-3);
  }
  // Nav links with heading
  .navigation-links li strong {
    font-size: 2.4rem;
  }
  // Nav links numbered
  .navigation-links.numbered li a {
    padding-left: 6rem;
    // With heading
    &:has(strong) {
      &::before {
        line-height: 1.1;
      }
    }
  }
}



// Limit text-width in frontpage boxes. Manually added class.

.running-text {
  max-width: 820px; // Equal to widest box in column layout.
}



// Accordion

h2,
h3 {
  &.accordion {
    color: var(--color-primary-1);
  }
}



// vrtx-article-body

.vrtx-article-body {
  border-bottom-color: var(--color-primary-2);
  @media (@screen-medium) {
    font-size: 2.2rem;
    font-weight: 300;
    strong {
      font-weight: bold; // Needed in FF for some reason
    }
    table {
      font-size: 1.8rem;
    }
  }
  .image-left,
  .image-right {
    // Small image. Manually set class
    &.user-small,
    &:has(.user-small) {
      width: 150px;
    }
  }
  blockquote {
    margin: 50px 0;
    &::before {
      content: "";
      background: url("../images/quote.svg") no-repeat;
      background-size: contain;
      display: block;
      width: 40px;
      height: 45px; // Adjusts spacing below
      @media (@screen-medium) {
        width: 50px;
        height: 55px; // Adjusts spacing below
      }
      @media (@screen-large) {
        position: absolute;
        margin: 0 0 0 -70px;
      }
    }
    p:first-child {
      @media (@screen-medium) {
        font-size: 3rem;
      }
    }
  }
}

// Page type specific

#vrtx-structured-article,
#vrtx-structured-event {
  .vrtx-article-body {
    @media (@screen-oversized) {
      blockquote {
        margin-left: -120px; // Align with logo
      }
      .image-right {
        margin-right: -400px;
        // Adjust for small size (subtract width-difference to maintain alignment)
        &.user-small,
        &:has(.user-small) {
          margin-right: -250px;
        }
      }
      .vrtx-facts-container.vrtx-container-right {
        margin-right: -500px;
      }
    }
  }
}



// Event dates emphasized when using "date-icon=[true]"

.vrtx-date-icon {
  background-color: var(--color-primary-1);
  color: white;
}



// Event lists and feeds calendar icon

.vrtx-resource .vrtx-time-and-place-container .time-and-place,
.vrtx-event-component .vrtx-time-and-place-container time:first-child {
  &::before {
    content: "";
    background: url("../images/calendar.svg") no-repeat;
    padding: 2px 24px 2px 0;
    margin-right: 6px;
  }
}



// Event page

#vrtx-structured-event {
  // byline
  .vrtx-byline {
    max-width: var(--width-soft-max);
    @media (@screen-medium) {
      margin-bottom: 80px;
    }
    .vrtx-time-and-place-container {
      position: relative;
      padding-left: 52px; // space for icon
      line-height: 1.3;
      font-weight: bold;
      span {
        font-weight: normal;
      }
      > *:nth-last-child(2) {
        margin-right: 5px; // spacing before "add to calendar"
      }
      // Calendar icon
      &::before {
        content: "";
        background: url("../images/calendar-white.svg") no-repeat;
        background-color: var(--color-primary-1);
        background-position: center 7px;
        border-radius: 100%;
        width: 40px;
        height: 40px;
        position: absolute;
        left: 0;
        // center vertically
        top: 0;
        bottom: 0;
        margin: auto 0;
      }
      .vrtx-number-of-comments-add-event-container {
        display: inline-block;
      }
    }
  }
}



// Pager

#main .vrtx-paging-wrapper {
  .marked,
  .vrtx-marked {
    background-color: var(--color-primary-1);
    color: white;
  }
}



// List pages

.vrtx-resources {
  .vrtx-title {
    @media (@screen-large) {
      font-size: 3rem;
    }
  }
}



// List/feed images

.vrtx-image img {
  aspect-ratio: 16/9;
}



// Media-wide

#main .media-wide {
  // Dark version
  &.dark {
    .vrtx-box-content {
      background-color: var(--color-primary-1);
      @media (@screen-below-large) {
        padding-bottom: 30px;
      }
      * {
        color: white;
      }
      .read-more::before {
        background-image: url("../images/arrow-white.svg");
      }
    }
  }
}



// Item-special

#main {
  .vrtx-frontpage-box {
    &.img-special-left,
    &.img-special-right {
      img {
        aspect-ratio: 4/3;
      }
      // Dark version
      &.dark {
        background-color: var(--color-primary-1);
        *,
        a {
          color: white;
        }
        .read-more::before {
          background-image: url("../images/arrow-white.svg");
        }
      }
    }
    &.link-special {
      border-color: var(--color-primary-2);
      box-shadow: inset 0 0 0 3px var(--color-primary-2-tint-90);
    }
  }
}



// column-list
// Manually added frontpage box class to get column-layout for any contained ul
// Don't think this was ever used. The multi-column class is probably better

.column-list {
  ul {
    list-style: none;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
    gap: var(--space);
  }
}



// Frontpage box class for column layout
// Needs content to in separate divs
// First p can be used for intro-text

.vrtx-frontpage-box {
  &.multi-column {
    .vrtx-box-content {
      display: grid;
      gap: 40px;
      grid-template-columns: repeat(4, 1fr);
      @media (@screen-below-large) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media (@screen-below-medium) {
        grid-template-columns: 1fr;
      }
      // Optional intro-text
      > p:first-child {
        grid-column: 1/-1;
        max-width: var(--width-text-max);
        margin: 0;
      }
      img {
        object-fit: cover;
        aspect-ratio: 16/9;
      }
    }
    &.rounded-image {
      .vrtx-box-content {
        img {
          aspect-ratio: 1/1;
          border-radius: 100%;
        }
      }
    }
  }
}